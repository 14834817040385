<template>
  <div>
    <div class="actions row col-lg-12 mb-5 p-0 m-0 justify-content-end">
      <div class="actions-right row col-lg-6 row justify-content-end p-0 m-0">
        <button type="button" class="btn btn-primary" @click="addMeetup">{{ (' Add Meetup') }}</button>
      </div>
    </div>
    <CTableWrapper ref="table" hover striped :caption="caption" :items="items" :actions="actions" :fields="fields" clickable-rows @row-clicked="rowClicked" @action-clicked="actionClicked" @update-requested="updateItemsRequested" :loading="loading">
    </CTableWrapper>
  </div>
</template>
 ««
<script>
import { mapState, mapActions } from 'vuex'
import CTableWrapper from '../controls/PaginatedTable.vue'
import router from '@/router';
 import InputGetMeetup from "@/classes/InputGetMeetup";
import { UTILS_HELPER } from "@/helpers/utils.js";

export default {
  components: { CTableWrapper },
  data () {
    return {
      caption: ('Meetups'),
      fields: [
        { key: 'name', label: ('Name'), _classes: 'font-weight-bold', sortable: false },
        { key: 'desc', label: ('Desc'), _classes: 'font-weight-bold', sortable: false },
        { key: 'date', label: ('Date'), _classes: 'font-weight-bold', sortable: false},
      ],      
      actions: [
        { key: 'view', label: ('view') },
        { key: 'remove', label: ('remove') }
      ],
      loading: false
    }
  },
  computed: {
    ...mapState('meetups', ['listOutput']),
    ...mapState('meetups', ['removeTask']),
    meetupsInfo: function() {
      return this.listOutput ? this.listOutput : null;
    },
    items: function()
    {
      let result = [];

      if(this.meetupsInfo)
      {
        result = this.meetupsInfo ? this.meetupsInfo : []
        result.forEach(items => {
            items["date"] = UTILS_HELPER.formatDate(items.date);
        });
      }
      
      return result;
    },
  },
  mounted: function()
  {
    this.updateItemsRequested();
  },
  methods: {
    ...mapActions('meetups', ['getList']),
    ...mapActions('meetups', ['removeMeetup']),
    rowClicked (item, index) {
      this.$router.push({name: "EditMeetups", params: { meetup: item } });
    },
    actionClicked(item, action){
      switch(action.key)
      {
        case 'view':
        {
          this.$router.push({name: "EditMeetups", params: { meetup: item } });
          break;
        }
        case 'remove':
          {
            if(confirm("Wanna remove this meetup?")){
              this.removeMeetup(item.id);
          }
          break;
        }
        default:
        {
          throw "The provided action is not supported!"; 
        }
      }
    },
    updateItemsRequested()
    {
      //Make request
      this.loading = true;

      let input = new InputGetMeetup();
      this.getList(input); 
    },
    addMeetup () 
    {
      router.push({ path: '/meetups/add' });
    },  
  },
  watch:
  {
    removeTask: function(val){
      // Meetup was deleted, reload list
      if(val){
        this.updateItemsRequested();
      }
    },
    listOutput: function(val){
      if(val){
        this.loading = false;
      }
    }
  }
}
</script>

 
<style>
th
{
  border-top: none !important;
}

tr
{
  cursor: pointer;
}

.status-button
{
  border: none;
  padding: 0;
  text-decoration: underline;
  color: #321fdb;
}
</style> 