import moment from 'moment'
const ADMIN_ROLEID = 1;
const COMPANY_ROLEID = 2;

export const UTILS_HELPER = {
    deepClone,
    convertToPascalCase,
    convertToCamelCase,
    formatDate
};

function deepClone(destination, source)
{
    return Object.assign(destination, JSON.parse(JSON.stringify(source)));
}


function convertToPascalCase(string)
{
    return string.replace(/(\w)(\w*)/g,
        function(g0,g1,g2){return g1.toUpperCase() + g2.toLowerCase();});
}

function formatDate(date)
{
    if(!(date instanceof Date))
    {
        date = new Date(date);
    }

    return moment(date).format('YYYY-MM-DD, h:mm a');
}
function convertToCamelCase(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
  }