<template>
  <CCard>
    <CCardHeader>
      <slot name="header">{{caption}}</slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable :hover="hover" :striped="striped" :bordered="bordered" :small="small" :fixed="fixed" :items="items" :fields="processedFields" :dark="dark" @row-clicked="rowClicked" :loading="loading">
        <!-- Actions -->
        <template v-if="actions.length > 0" #actions="{item}">
          <td class="py-2">
            <CButton class="mr-2" v-for="action in actions" :key="action.key" color="primary" variant="outline" square size="sm" @click="onActionClicked(item, action)">
              {{action.label}}
            </CButton>
          </td>
        </template>
      </CDataTable>
   <!--    <div class="col-lg-12 row d-flex justify-content-between">
        <div class="form-inline float-sm-right d-flex">
          <label class="mr-2">{{ ('Items per page') }}</label> 
          <select v-model="itemsPerPage" class="form-control align-self-center">
              <option value="10" selected="selected" disabled="disabled" hidden="hidden">10</option> 
              <option val="5">5</option>
              <option val="10">10</option>
              <option val="20">20</option>
              <option val="50">50</option>
          </select>
        </div>
        <CPagination align="end" :active-page.sync="currentPage" :pages="pages"/>
      </div> -->
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  data () {
    return {
      itemsPerPage: localStorage.itemsPerPage ?? 10,          
      currentPage: 1,
      actionWasClicked: false, // By default, the action button event is propagating to the row,
      // sorterValue: this.defaultSorterValue
    }
  },
    props: {
    loading: {
      type: Boolean
    },
    sorter: {
      type: Object,
      default(){
        return null
      }
    },
    defaultSorterValue: {
      type: Object,
      default(){
        return {}
      }
    },
    items: Array,
    pages: {
      type: Number,
      default(){
        return 1
      }
    },
    fields: {
      type: Array,
      default () {
        return []
      }
    },
    actions: {
      type: Array,
      default () {
        return []
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    captionIcon: {
      type: String,
      default: 'cil-grid'
    },
    canDelete: Boolean,
    hover: Boolean,
    striped: Boolean,
    bordered: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  computed:
  {
    processedFields(){
      var result = this.fields;

      if(result.filter(e => e.key === 'actions').length == 0 && this.actions.length > 0)
      {
        result.push({ key: 'actions', label: ('actions'), sorter: false },);
      }

      return result;
    }
  },
  watch: 
  { 
    currentPage: function (val) {
      this.$emit('update-requested', this.currentPage, this.itemsPerPage);
    },
    itemsPerPage: function (val) {
      this.$emit('update-requested', this.currentPage, this.itemsPerPage);
      localStorage.itemsPerPage = val;
    },
    defaultSorterValue: function(val){
      this.sorterValue = this.defaultSorterValue;
    },
    sorterValue: function(val){
      this.$emit('sorter-value-changed', val);
    }
  },
  methods:
  {
    reset () {
      this.currentPage = 1;
    },
    rowClicked (item, index) {
      if(!this.actionWasClicked)
      {
        this.$emit('row-clicked', item, index);
        this.actionWasClicked = false;
      }
    },
    onActionClicked(item, action){
      this.actionWasClicked = true;
      this.$emit('action-clicked', item, action);
    }
  }
}
</script>
<style>
tbody > tr > td 
{
    text-align:center;
}

thead > tr > th 
{
    text-align:center;
}

.pagination
{
  margin: 0;
}
</style>
